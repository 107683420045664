import { MIN_BROWSER_VERSIONS } from './constants';
import { botsRegex } from './regex';

export function isSupportedBrowser(): boolean {
  const userAgent = navigator.userAgent.toLowerCase();

  const isSafari =
    userAgent.includes('safari/') &&
    userAgent.includes('version/') &&
    !userAgent.includes('crios/');

  const isChromeIOS = userAgent.includes('crios/');
  const isChrome = userAgent.includes('chrome/');
  const isEdge = userAgent.includes('edg/');
  const isFirefox = userAgent.includes('firefox/');
  const isBot = botsRegex.test(userAgent);

  if (isBot) {
    return true;
  }

  if (isSafari) {
    const versionMatch = userAgent.match(/version\/(\d+)/);
    const version = versionMatch ? parseInt(versionMatch[1]) : 0;
    return version >= MIN_BROWSER_VERSIONS.safari;
  }

  if (isEdge) {
    const versionMatch = userAgent.match(/edg\/(\d+)/);
    const version = versionMatch ? parseInt(versionMatch[1]) : 0;
    return version >= MIN_BROWSER_VERSIONS.edge;
  }

  if (isChromeIOS) {
    const versionMatch = userAgent.match(/crios\/(\d+)/);
    const version = versionMatch ? parseInt(versionMatch[1]) : 0;
    return version >= MIN_BROWSER_VERSIONS.chrome;
  }

  if (isChrome) {
    const versionMatch = userAgent.match(/chrome\/(\d+)/);
    const version = versionMatch ? parseInt(versionMatch[1]) : 0;
    return version >= MIN_BROWSER_VERSIONS.chrome;
  }

  if (isFirefox) {
    const versionMatch = userAgent.match(/firefox\/(\d+)/);
    const version = versionMatch ? parseInt(versionMatch[1]) : 0;
    return version >= MIN_BROWSER_VERSIONS.firefox;
  }

  return false;
}

export function getBrowserName(userAgent) {
  const browsers = {
    Edge: /Edg\/([\d.]+)/,
    Chrome: /Chrome\/([\d.]+)/,
    Chrome_iOS: /CriOS\/([\d.]+)/,
    Firefox: /Firefox\/([\d.]+)/,
    Safari: /Safari\/([\d.]+)/,
  };

  for (const browser in browsers) {
    const version = browsers[browser].exec(userAgent);
    if (version) return browser;
  }

  return 'Unknown';
}

export function getBrowserVersion(userAgent, browserName) {
  let versionRegex = new RegExp(`${browserName}/([\\d.]+)`);

  // Safari is a little different (whats new)
  // Edge also slightly different having Edg instead of Edge
  if (browserName === 'Safari') {
    versionRegex = new RegExp(`Version/([\\d.]+)`);
  } else if (browserName === 'Edge') {
    versionRegex = new RegExp(`Edg/([\\d.]+)`);
  } else if (browserName === 'Chrome_iOS') {
    versionRegex = new RegExp(`CriOS/([\\d.]+)`);
  }

  const versionMatch = versionRegex.exec(userAgent);
  if (!versionMatch) {
    return '';
  }

  const fullVersion = versionMatch[1];
  const majorVersion = fullVersion.split('.')[0];
  return majorVersion;
}
