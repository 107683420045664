import * as Toast from '@radix-ui/react-toast';
import Image from 'next/legacy/image';
import { ReactElement, useEffect, useState } from 'react';
import { BiX } from 'react-icons/bi';

import { Modal } from '@components/Modal/Modal';
import { Paragraph } from '@components/Typography/Paragraph/Paragraph';
import { TextLink } from '@components/Typography/TextLink/TextLink';
import {
  getBrowserName,
  getBrowserVersion,
  isSupportedBrowser,
} from '@lib/browser';
import { SUPPORTED_BROWSERS } from '@lib/constants';

export function BrowserCompatibilityAlert() {
  const userAgent = navigator.userAgent;
  const browserName = getBrowserName(userAgent);
  const browserVersion = getBrowserVersion(userAgent, browserName);

  return (
    <section className="unsupported-browser-alert max-w-[500px]">
      <h1>Unsupported Browser</h1>
      <Paragraph>
        You're currently using{' '}
        <b>
          {browserName} {browserVersion}
        </b>{' '}
        which is unsupported by the Bulk Nutrients website.
      </Paragraph>
      <Paragraph>
        For the best experience browsing and ordering quality supplements, we
        suggest using one of the following...
      </Paragraph>
      <br />
      <div className="grid grid-cols-2 gap-2.5 sm:grid-cols-4">
        {SUPPORTED_BROWSERS.map((b) => (
          <div key={b.name} className="flex flex-col items-center gap-2.5">
            <Image
              src={b.image}
              width={80}
              height={80}
              alt={`${b.name} icon`}
              layout="fixed"
            />
            <TextLink target="_blank" href={b.download}>
              {b.name}
            </TextLink>
          </div>
        ))}
      </div>
      <br />
      <Paragraph>
        As always, reach out if you{' '}
        <TextLink href="/contact-us">need the help</TextLink> of our friendly
        Customer Service team.
      </Paragraph>
    </section>
  );
}

export function UnsupportedBrowserModal(): ReactElement {
  const [showModal, setShowModal] = useState(false);

  // 5s after load, check browser version
  useEffect(() => {
    const timer = setTimeout(() => {
      const isSupported = isSupportedBrowser();
      if (!isSupported) {
        setShowModal(true);
      }
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <Modal show={showModal} setShow={setShowModal}>
      <BrowserCompatibilityAlert />
    </Modal>
  );
}

export function UnsupportedBrowserToast(): ReactElement {
  const [open, setOpen] = useState(false);

  // 5s after load, check browser version
  useEffect(() => {
    const timer = setTimeout(() => {
      const isSupported = isSupportedBrowser();
      if (!isSupported) {
        setOpen(true);
      }
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <Toast.Provider>
      <Toast.Root
        open={open}
        onOpenChange={setOpen}
        className="rounded-1 items-center bg-white p-4 text-black shadow-toast
        data-[state=cancel]:translate-x-0
        data-[state=move]:translate-x-[var(--radix-toast-swipe-move-x)]
        data-[state=closed]:animate-[fade-out_100ms_ease-in]
        data-[state=end]:animate-[toastSwipeOut_100ms_ease-out] data-[state=open]:animate-[toastSlideIn_150ms_cubic-bezier(0.16_1_0.3_1)] data-[state=cancel]:transition-transform data-[state=cancel]:duration-200
        data-[state=cancel]:ease-out
        
        "
      >
        <Toast.Title className="font-semibold">
          You're currently using an unsupported browser
        </Toast.Title>
        <Toast.Description></Toast.Description>
        <Toast.Action asChild altText="Find out more about browser support">
          <TextLink href="/supported-browsers">Find out more</TextLink>
        </Toast.Action>
        <Toast.Close asChild>
          <button
            type="button"
            aria-label="Close"
            className="text-bold absolute right-6 top-6 cursor-pointer border-none bg-transparent text-2xl text-black hover:text-orange hover:no-underline focus:text-orange focus:no-underline"
          >
            <BiX />
          </button>
        </Toast.Close>
      </Toast.Root>

      <Toast.Viewport className="max-w-screen fixed bottom-0 left-0 right-0 z-3 m-auto flex w-[390px] list-none flex-col gap-2.5 p-6 outline-none" />
    </Toast.Provider>
  );
}
